import chunk from "lodash/chunk";
import { ReactElement, useState } from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";
import Select from "react-select";
import { FormGroup } from "reactstrap";
import FieldFeedback from "ui/FieldFeedback";
import FieldLabel from "ui/FieldLabel";
import { MenuComponent } from "./components/MenuComponent";
import Styles from "./styles";

export type TOption = {
  value: string | number;
  label?: string | number;
  isDisabled?: boolean;
};

export type TColorSchemes = {
  [key: string | number]: {
    color: string;
    backgroundColor: string;
    ":hover": {
      color: string;
      backgroundColor: string;
    };
  };
};

type Props = {
  name: string;
  title?: string;
  placeholder?: string;
  control: Control<any, any>;
  errors?: FieldErrors<any>;
  isRequired?: boolean;
  isStared?: boolean;
  options?: TOption[];
  chunkSize?: number;
  isMulti?: boolean;
  isNotClearable?: boolean;
  errorText?: string;
  hideArrow?: boolean;
  disabled?: boolean;
  onChange?: (_: (string | number)[] | string | number | undefined) => void;
  colorSchemes?: TColorSchemes;
  infoText?: string | ReactElement;
  onInputChange?: (_: string | undefined) => void;
};

export const SelectField = ({
  name,
  title,
  placeholder,
  control,
  errors,
  isRequired,
  isStared,
  options,
  chunkSize,
  isMulti,
  isNotClearable,
  errorText,
  hideArrow,
  disabled,
  onChange,
  colorSchemes,
  infoText,
  onInputChange
}: Props) => {
  const [optionsChunks, setOptionsChunks] = useState(
    chunkSize ? chunk(options, chunkSize) : undefined
  );
  const [page, setPage] = useState(0);

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: isRequired }}
      render={({ field: { value, onChange: innerOnChange, onBlur } }) => (
        <FormGroup>
          {title ? (
            <FieldLabel
              title={title}
              isRequired={isRequired || isStared}
              info={
                infoText
                  ? {
                      text: infoText,
                      id: `info_${name}`
                    }
                  : undefined
              }
            />
          ) : null}

          {isMulti && options && !optionsChunks ? (
            <Select
              id={name}
              placeholder={placeholder || title}
              value={options.filter((item) => (value as (string | number)[])?.includes(item.value))}
              onChange={(e) => {
                const newValues = e.map(({ value: newVal }) => newVal);
                innerOnChange(newValues);
                if (onChange) onChange(newValues);
              }}
              onInputChange={(e) => {
                if (onInputChange) onInputChange(e);
              }}
              options={options}
              isMulti
              components={
                hideArrow ? { DropdownIndicator: () => null, IndicatorSeparator: () => null } : {}
              }
              isClearable={!isNotClearable}
              isDisabled={disabled}
              styles={{
                control: (styles): any => Styles.control(styles, errors, name),
                option: (styles, { data }): any => Styles.option(styles, data, colorSchemes),
                multiValue: (styles, { data }): any =>
                  Styles.multiValue(styles, data, colorSchemes),
                multiValueLabel: (styles, { data }): any => Styles.multiValueLabel(styles, data),
                menu: (styles): any => Styles.menu(styles)
              }}
              onBlur={onBlur}
            />
          ) : null}

          {!isMulti && options && !optionsChunks ? (
            <Select
              placeholder={placeholder || title}
              value={value === undefined ? null : options.find((item) => item.value === value)}
              onChange={(newVal) => {
                const newValue = newVal && newVal.value !== null ? newVal.value : undefined;
                innerOnChange(newValue);
                if (onChange) onChange(newValue);
              }}
              onInputChange={(e) => {
                if (onInputChange) onInputChange(e);
              }}
              options={options}
              components={
                hideArrow ? { DropdownIndicator: () => null, IndicatorSeparator: () => null } : {}
              }
              isClearable={!isNotClearable}
              isDisabled={disabled}
              styles={{
                control: (styles): any => Styles.control(styles, errors, name),
                option: (styles, { data }): any => Styles.option(styles, data, colorSchemes),
                singleValue: (styles, { data }): any =>
                  Styles.singleValue(styles, data, colorSchemes),
                menu: (styles): any => Styles.menu(styles)
              }}
              onBlur={onBlur}
            />
          ) : null}

          {isMulti && optionsChunks ? (
            <Select
              id={name}
              placeholder={title}
              value={options?.filter((item) =>
                (value as (string | number)[])?.includes(item.value)
              )}
              onChange={(e) => {
                const newValues = e.map(({ value: newVal }) => newVal);
                innerOnChange(newValues);
                if (onChange) onChange(newValues);
              }}
              options={optionsChunks[page]}
              isMulti
              components={{
                ...(hideArrow
                  ? { DropdownIndicator: () => null, IndicatorSeparator: () => null }
                  : {}),
                Menu: (props) => MenuComponent(props, page, setPage, optionsChunks.length)
              }}
              isClearable={!isNotClearable}
              isDisabled={disabled}
              styles={{
                control: (styles): any => Styles.control(styles, errors, name),
                option: (styles, { data }): any => Styles.option(styles, data, colorSchemes),
                multiValue: (styles, { data }): any =>
                  Styles.multiValue(styles, data, colorSchemes),
                multiValueLabel: (styles, { data }): any => Styles.multiValueLabel(styles, data),
                menu: (styles): any => Styles.menu(styles)
              }}
              onInputChange={(newValue) => {
                const filteredOptions = options?.filter(({ label }) =>
                  label
                    ?.toString()
                    .toLowerCase()
                    ?.includes((newValue || "").toLowerCase())
                );
                setOptionsChunks(chunk(filteredOptions, chunkSize));
              }}
              onBlur={onBlur}
            />
          ) : null}

          {!isMulti && optionsChunks ? (
            <Select
              placeholder={title}
              value={value === undefined ? null : options?.find((item) => item.value === value)}
              onChange={(newVal) => {
                const newValue = newVal && newVal.value !== null ? newVal.value : undefined;
                innerOnChange(newValue);
                if (onChange) onChange(newValue);
              }}
              options={optionsChunks[page]}
              components={{
                ...(hideArrow
                  ? { DropdownIndicator: () => null, IndicatorSeparator: () => null }
                  : {}),
                Menu: (props) => MenuComponent(props, page, setPage, optionsChunks.length)
              }}
              isClearable={!isNotClearable}
              isDisabled={disabled}
              styles={{
                control: (styles): any => Styles.control(styles, errors, name),
                option: (styles, { data }): any => Styles.option(styles, data, colorSchemes),
                singleValue: (styles, { data }): any =>
                  Styles.singleValue(styles, data, colorSchemes),
                menu: (styles): any => Styles.menu(styles)
              }}
              onInputChange={(newValue) => {
                const filteredOptions = options?.filter(({ label }) =>
                  label
                    ?.toString()
                    .toLowerCase()
                    ?.includes((newValue || "").toLowerCase())
                );
                setOptionsChunks(chunk(filteredOptions, chunkSize));
              }}
              onBlur={onBlur}
            />
          ) : null}

          <FieldFeedback name={name} errors={errors} errorText={errorText} />
        </FormGroup>
      )}
    />
  );
};

export const selectColorSchemes = {
  red: {
    color: "white",
    backgroundColor: "#dc3545",
    ":hover": {
      color: "white",
      backgroundColor: "#bb2d3b"
    }
  },
  green: {
    color: "white",
    backgroundColor: "#198754",
    ":hover": {
      color: "white",
      backgroundColor: "#157347"
    }
  },
  yellow: {
    color: "black",
    backgroundColor: "#ffc107",
    ":hover": {
      color: "black",
      backgroundColor: "#ffca2c"
    }
  }
};
