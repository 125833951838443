import { getContract } from "api/contract_item";
import { APIData } from "api/types";
import { AxiosError } from "axios";
import { Contract, ID } from "models";
import { Dispatch, SetStateAction } from "react";
import { FieldValues, UseFormReset } from "react-hook-form";
import { useQuery } from "react-query";
import { PosibleContractStatuses } from "../types";

const useLoadContract = (
  id: ID,
  reset: UseFormReset<FieldValues>,
  setStatus: Dispatch<SetStateAction<PosibleContractStatuses>>
) => {
  const { isLoading, error, data } = useQuery<APIData<Contract>, AxiosError>({
    queryKey: ["contract_item", id],
    enabled: !!id,
    queryFn: () => getContract(id),
    retry: (failureCount, error) => {
      if (error.response?.status === 404) {
        return false;
      }
      return failureCount < 3;
    },
    refetchOnWindowFocus: false,
    onSuccess: ({ data, status, error_message }) => {
      if (status) {
        reset({
          ...data,
          vat_included:
            typeof data.vat_included === "boolean" ? +data.vat_included : data.vat_included,
          noNumber: data.contract_number ? false : true,
          self_ad_participant: data.client,
          is_agent_acting_for_publisher:
            typeof data.is_agent_acting_for_publisher === "boolean"
              ? +data.is_agent_acting_for_publisher
              : null
        });
        let st: PosibleContractStatuses = "LOADING";

        if (data.status === 1) {
          st = "ACTIVE";
        } else if (data.status === 2) {
          st = "DRAFT";
        }

        setStatus(st);
      } else {
        console.log(error_message);
      }
    }
  });

  return {
    isLoading,
    error,
    initialContractsIds: data?.data?.initial_contracts,
    // searchClientParticipantsValueDefault: data?.data.extra_fields?.client?.name,
    // searchContractorParticipantsValueDefault: data?.data.extra_fields?.contractor?.name,
    data
  };
};

export default useLoadContract;
