import { format } from "date-fns";
import { ID } from "models";
import { Badge } from "reactstrap";
import Tooltip from "ui/Tooltip";
import getTitleFromDict from "utils/getTitleFromDict";
import TStatusDisplay from "./type";

const statusBadgeColors = {
  SYNC_NOT_NEEDED: "bg-primary",
  READY_TO_SYNC: "bg-primary",
  IS_SYNCHRONIZING: "bg-info",
  SYNCHRONIZED: "bg-success",
  SYNC_ERROR: "bg-danger"
};

export const statusBadgeText: {
  [k: string]: (
    _ordAccount?: string,
    _itemId?: ID,
    _error_message?: string,
    _synced_at?: string
  ) => string;
} = {
  SYNC_NOT_NEEDED: () => "Синхронизация не нужна",
  READY_TO_SYNC: () => "Готово к синхронизации",
  IS_SYNCHRONIZING: (ordAccount) => `Регистрируется в ЕРИР на аккаунт ${ordAccount}.`,
  SYNCHRONIZED: (ordAccount) => `Зарегистрировано в ЕРИР на аккаунт ${ordAccount}.`,
  SYNC_ERROR: (ordAccount, itemId, error_message, synced_at) =>
    `${
      synced_at ? `${format(new Date(synced_at), "dd.MM.yyyy, HH:MM:SS")} ` : ""
    }Ошибка регистрации ${itemId} в аккаунте ${ordAccount}: ${error_message}.`
};

const StatusInline = ({ data, dictionary }: TStatusDisplay) => {
  if (data?.extra_fields?.sync_status?.length) {
    const { sync_status: rowSyncStatus } = data.extra_fields;
    const { id: rowId } = data;
    return (
      <div className="d-inline-flex flex-wrap gap-1">
        {rowSyncStatus.map((syncStatus) => {
          const itemId = `${syncStatus.ord}-${new Date(syncStatus.synced_at).getTime()}-${rowId}`;
          return (
            <Tooltip
              key={itemId}
              id={itemId}
              maxWidth="10em"
              target={
                <Badge className={statusBadgeColors[syncStatus.status]} pill id={itemId}>
                  {syncStatus.ord.charAt(0).toUpperCase()}
                </Badge>
              }
            >
              {statusBadgeText[syncStatus.status](
                syncStatus.ord,
                rowId,
                syncStatus.error_message,
                syncStatus.synced_at
              )}
            </Tooltip>
          );
        })}
      </div>
    );
  }

  return <>{data?.status ? getTitleFromDict(data.status, dictionary) : ""}</>;
};

export default StatusInline;
