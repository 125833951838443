import { format } from "date-fns";
import { DefaultSyncStatus } from "models";
import { useState } from "react";
import { Button } from "reactstrap";

export const SyncStatusText: {
  [k: string]: (_ordAccount?: string, _error_message?: string, _synced_at?: string) => string;
} = {
  SYNC_NOT_NEEDED: () => "<p class='mb-0'>Синхронизация не нужна</p>",
  READY_TO_SYNC: () => "<p class='mb-0'>Готово к синхронизации</p>",
  IS_SYNCHRONIZING: (ordAccount, _error_message, synced_at) =>
    `<p class="mb-0">${
      synced_at ? `${format(new Date(synced_at), "dd.MM.yyyy, HH:MM:SS")} ` : ""
    }Регистрируется в ЕРИР на аккаунт ${ordAccount}</p>`,
  SYNCHRONIZED: (ordAccount, _error_message, synced_at) =>
    `<p class="mb-0 text-success">${
      synced_at ? `${format(new Date(synced_at), "dd.MM.yyyy, HH:MM:SS")} ` : ""
    }Зарегистрировано в ЕРИР на аккаунт ${ordAccount}</p>`,
  SYNC_ERROR: (ordAccount, error_message, synced_at) =>
    `<p class="mb-0 text-danger">${
      synced_at ? `${format(new Date(synced_at), "dd.MM.yyyy, HH:MM:SS")} ` : ""
    }Ошибка регистрации в аккаунте ${ordAccount}: ${error_message?.replaceAll(";", ";<br />")}</p>`
};

const getPreviewParagraph = (paragraphs: string[]): string | null => {
  if (paragraphs.at(0)!.split("<br />").length > 1)
    return paragraphs.at(0)!.split("<br />").at(0)!.concat("...</p>");

  if (paragraphs.length > 1) return paragraphs.at(0)!;

  return null;
};

export const AttributesSyncStatus = ({
  syncStatus
}: {
  syncStatus?: DefaultSyncStatus[] | undefined;
}) => {
  const [showAll, setShowAll] = useState(false);

  if (!(syncStatus && syncStatus.length)) return null;

  const paragraphs = syncStatus.map(({ status, synced_at, ord, error_message }) =>
    SyncStatusText[status](ord, error_message, synced_at)
  );

  const previewParagraph = getPreviewParagraph(paragraphs);

  return (
    <tr>
      <td>Ответ ЕРИР</td>
      <td>
        {previewParagraph && !showAll ? (
          <>
            <div dangerouslySetInnerHTML={{ __html: previewParagraph }} />
            <Button onClick={() => setShowAll(true)} color="link" className="p-0">
              Показать еще
            </Button>
          </>
        ) : (
          <>
            <div dangerouslySetInnerHTML={{ __html: paragraphs.join("") }} />
            {previewParagraph ? (
              <Button onClick={() => setShowAll(false)} color="link" className="p-0">
                Скрыть
              </Button>
            ) : null}
          </>
        )}
      </td>
      <td></td>
    </tr>
  );
};
