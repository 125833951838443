import EditorFooter from "Layout/EditorFooter";
import omit from "lodash/omit";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { StateProps } from "reducers";
import TFormAddPossibleStatuses from "types/TFormAddPossibleStatuses";
import { scrollToError } from "utils/scrollToError";
import { InvoiceForm } from "./components/form";
import { NoAllocationsFileModal } from "./components/noAllocationsFileModal";
import useAddInvoice from "./hooks/useAddInvoice";

const InvoiceAddForm = () => {
  const [status, setStatus] = useState<TFormAddPossibleStatuses>("ACTIVE");
  const invoiceLastSearch = useSelector((state: StateProps) => state.LastSearchParams.invoice);
  const [_amountFromAllocationsFile, setAmountFromAllocationsFile] = useState<number | undefined>();
  const [isNoAllocationsFileModalOpen, setIsNoAllocationsFileModalOpen] = useState(false);
  const toggleNotAllocationsFileModal = () => setIsNoAllocationsFileModalOpen((prev) => !prev);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    setValue
  } = useForm();

  const add = useAddInvoice(invoiceLastSearch, watch, setError);

  const onAdd = () => {
    add({
      invoice: {
        ...omit(watch(), "noNumber"),
        status: status === "DRAFT" ? 2 : 1
      }
    });
  };

  const onSubmit = handleSubmit(
    () => {
      // if (amountFromAllocationsFile && +watch("amount") !== amountFromAllocationsFile)
      //   createNotification(
      //     "error",
      //     `Указанная сумма ${watch(
      //       "amount"
      //     )}. Рассчитанная сумма ${amountFromAllocationsFile}. Значения должны совпадать`
      //   );
      // else {
      if (!watch("allocations_file") && status !== "DRAFT") {
        toggleNotAllocationsFileModal();
      } else {
        onAdd();
      }
      // }
    },
    (err) => {
      if (err) {
        scrollToError(err);
      }
    }
  );

  return (
    <div>
      <h1>Добавление акта</h1>
      <InvoiceForm
        control={control}
        errors={errors}
        onSubmit={onSubmit}
        buttons={
          <EditorFooter
            left={
              <Button type="submit" color="primary" onClick={() => setStatus("DRAFT")}>
                Сохранить черновик
              </Button>
            }
            right={
              <>
                <Button href={`#/invoice/${invoiceLastSearch}`} tag="a" color="primary" outline>
                  Отменить
                </Button>
                <Button
                  type="submit"
                  color="danger"
                  onClick={() => setStatus("ACTIVE")}
                  // disabled={!watch("allocations_file")}
                >
                  Отправить в ЕРИР
                </Button>
              </>
            }
          />
        }
        watch={watch}
        setAmountFromAllocationsFile={setAmountFromAllocationsFile}
        setValue={setValue}
        isDraft={status === "DRAFT"}
      />
      <NoAllocationsFileModal
        isOpen={isNoAllocationsFileModalOpen}
        toggle={toggleNotAllocationsFileModal}
        onContinue={onAdd}
      />
    </div>
  );
};

export default InvoiceAddForm;
